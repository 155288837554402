import React, { Children } from "react";
import { AnimateKeyframes } from "react-simple-animate";

import styles from "./icon-text.module.scss";
import cx from "classnames";

const IconText = ({ size, animate, reverse, icon, children }) => {
  let options = {
    [styles.medium]: size == undefined || size == "m",
    [styles.large]: size == "l",
    [styles.small]: size == "s",
    [styles.reverse]: reverse == true,
    [styles.animate]: animate == true,
  };

  let iconTextStyles = cx(styles.iconText, options);

  return (
    <div className={iconTextStyles}>
      <div className={styles.icon}>
        <img src={`/icon/${icon}.svg`} alt={icon} />
      </div>
      <span className={styles.text}>{children}</span>
    </div>
  );
};

export default IconText;
