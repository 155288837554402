import React, { Children } from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import Button from "../system/button";
import IconText from "../system/icon-text";

import styles from "./carer-preview.module.scss";

function truncateString(string, length) {
  if (!string) return "";
  if (string.length < length) return string;
  const truncated = string.substr(0, length);
  return `${truncated}...`;
}

const WorkingArea = ({ carer }) => {
  const { memberLocation, memberRadius } = carer;

  let message;

  if (!memberLocation | !memberRadius) {
    message = `Wellington`;
  } else {
    message = `${memberLocation}`;
  }

  return (
    <IconText size="s" icon="location">
      {message}
    </IconText>
  );
};

const MemberSince = ({ carer }) => {
  const { memberSince } = carer;
  let message,
    newTime = new Date().getTime() - 30 * 24 * 60 * 60 * 1000;

  if (carer.fullName == "Dawn Ngakane") {
    message = `Founding member`;
  } else if (memberSince < newTime) {
    message = "New member";
  } else {
    message = `Member since ${memberSince}`;
  }

  return (
    <IconText size="s" icon="logo_sml">
      {message}
    </IconText>
  );
};

export default ({ carer }) => (
  <div className={styles.carer}>
    <Link to={`/carer/${carer.slug}`}>
      <div className={styles.info}>
        <div>
          <h4 className={styles.name}>{carer.fullName}</h4>
          <ul className={styles.meta}>
            <li>
              <div className={styles.metaItem}>
                <MemberSince className={styles.since} carer={carer} />
              </div>
            </li>
            <li>
              <div className={styles.metaItem}>
                <WorkingArea className={styles.location} carer={carer} />
              </div>
            </li>
          </ul>
        </div>
        <Img className={styles.image} alt="" fluid={carer.carerImage.fluid} />
      </div>
    </Link>
    <div className={styles.bio}>
      <p className={styles.about}>{truncateString(carer.about.about, 260)}</p>
    </div>
    <Button type="alt" to={`/carer/${carer.slug}`} size="s">
      Learn more about {carer.fullName}
    </Button>
  </div>
);
